@import "~@/assets/scss/variables.scss";
























































































































.site-settings-container {
  height: calc(100vh - 105px)
}

.site-settings-container::v-deep .tab-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.site-settings-container::v-deep .tab-pane.active {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.site-settings-default-metrics {
  max-width: 1000px;
}

.site-settings-default-information {
  max-width: 500px;
}
